<template>
    <t-navbar title="移动卡片" :fixed="false" left-arrow @left-click="goBack" />
   <div id="app" class="container">

        <div v-for="item in cards" :data-swapy-slot="'slot-' + item.card_id"
            :key="item.card_id" 
            style="display: flex; height: 50px; padding: 10px; border-bottom: 1px solid #ECECEC;">
           <div class="float:left;width:80%;" :data-swapy-item="'item-' + item.card_id">
                <icon-font name="move" style="width: 30px;"/> {{ item.title }}
           </div> 
   
        </div>
   </div>
  
</template>

<script>
  /* eslint-disable */
import api from '@/common/api'; 
import { IconFont } from 'tdesign-icons-vue-next';
import { Toast } from 'tdesign-mobile-vue';
import { createSwapy } from 'swapy'
import Swapy from 'swapy';

export default {
  name: 'App',
  components: {
    IconFont
  },
  data() {
    return {
        cards: []
    }
  },
  created() {
    this.loadData()

    
  },
  methods: {
   goBack() {
     this.$router.back()
   },
   loadData() {
        const page_id = this.$route.params.page_id
        api.get('/pages/' + page_id + '/cards')
        .then(response => {
            
            const result = response.data;
            if (result.status == 'success') {
                this.cards = result.data;
                this.readyToMove()
            } else {
                Toast('加载失败')
            }
        })
   },
   readyToMove() {
    // const container = document.querySelector('.container')
    // const swapy = createSwapy(container, {
    //     animation: 'dynamic' // or spring or none
    // })
    // swapy.enable(true)
   }


  }
};
</script>


<style scoped>
</style>