<template>
     <t-navbar title="修改卡片" :fixed="false" left-arrow @left-click="goBack" />
    <div id="app" class="container">
    
    <t-message v-model="showMessage" :offset="['10px', '16px']" :duration="5000" content="卡片修改成功" />

    <form @submit.prevent="submitForm">
    <table class="table table-striped">
        <tr>
            <td>Title</td>
            <td><input type="text" v-model="card.title" value="" class="col-6"></td>
        </tr>
        <tr>
            <td>MagicWord</td>
            <td><input type="text" v-model="card.magic_word" value="" class="col-6"></td>
        </tr>
        <tr>
            <td>SQL</td>
            <td>
                <textarea v-model="card.query_sql" class="col-12">
                    Hello
                </textarea>

            </td>
        </tr>
        <tr>
            <td>type</td>
            <td><select v-model="card.type">
                    <option value="list">list</option>
                    <option value="bar">bar</option>
                    <option value="pie">pie</option>
                    <option value="line">line</option>
                </select></td>
        </tr>
        <tr>
            <td>Columns</td>
            <td><input type="text" v-model="card.columns" value="" class="col-6"></td>
        </tr>
        <tr>
            <td></td>
            <td>
                <button class="btn btn-primary">保存</button>
            </td>
        </tr>
    </table>
</form>
    </div>
   
 </template>
 
 <script>
   /* eslint-disable */
 import api from '@/common/api'; 
 import 'bootstrap/dist/css/bootstrap.min.css';
 
 
 
 import { onMounted } from 'vue';
 onMounted(() => {
   // fetchData();
 });
 
 export default {
   name: 'App',
   components: {
     
   },
   data() {
     return {
         card: {},
         showMessage: false
     }
   },
   created() {
     this.loadData()
    
   },
   methods: {
    goBack() {
      this.$router.back()
    },
    loadData() {
      var url = '/cards/' + this.$route.params.card_id
      api.get(url)
       .then(response => {
          this.card = response.data.card
          // console.log(this.card);
       })
       .catch(error => {
         console.error('Fetching data failed', error);
       });
    },

    async submitForm() {
      api.post('/cards/update', this.card).then(response => {
        this.showMessage = true
        this.$router.back();
      })

    }
   }
 };
 </script>

 
<style scoped>
textarea {
  height: 300px;
}
</style>