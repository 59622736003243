/* eslint-disable */
// store.js
import { createStore } from 'vuex';

// 假设的cookies操作函数
function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
  options = options || {};
  let expires = options.expires;

  if (typeof expires == "number" && expires) {
    let d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    console.log('过期日期' + expires.toUTCString())
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = name + "=" + value;
  for (let prop in options) {
    updatedCookie += "; " + prop;
    let propValue = options[prop];
    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }

  document.cookie = updatedCookie;
}

const store = createStore({
  state() {
    return {
      account: {
        userId: '',
        userName: '',
        avatar: '',
        profile: '',
        vip_level: 0
      },
    };
  },
  getters: {
    account: (state) => {
      // state.account,
      if (state.account.userId !== '') {
        return state.account;
      } else {
        // 从cookies中读取account信息
        const accountStr = getCookie('account');
        try {
          if (accountStr)
              return JSON.parse(accountStr);
          else 
            return { userId: '', userName: '', avatar: '', profile: '', vip_level: 0 };

        } catch (e) {
          console.log(e);
          return { userId: '', userName: '', avatar: '', profile: '', vip_level: 0 };
        }
      }
    },
    isLogin: (state) => {
      return state.account.userId !== '';
    },
    isVip: (state) => {
      return state.account && state.account.vip_level > 0;
    },
    isAccount: (state) => (user_id) => {
      return state.account.userId === user_id;
    }
  },
  mutations: {
    setAccount(state, account) {
      state.account = account;
      // 存储account信息到cookies
      setCookie('account', JSON.stringify(account), { expires: 30 * 24 * 60 * 60 });
    },
  },
  actions: {
    loginSuccess({ commit }, account) {
      commit('setAccount', account);
    },
    logoutSuccess({ commit }) {
      commit('setAccount', {
        userId: '',
        userName: '',
        avatar: '',
        profile: '',
        vip_level: 0
      });
    },

    loadAccount({ commit }) {
      // 从cookies加载account信息到state
      const account = store.getters.account;
      commit('setAccount', account);
    }
  },
});
store.dispatch('loadAccount');

export default store;