<template>
    <div class="loading-bg page-center">
        <t-loading theme="dots" size="40px" class="page-center"/>
    </div>
</template>
    
<script>
/* eslint-disable */
export default {
    name: 'Loading',
    components: {
    },
};
</script>