<template>
  <div>
    <Bar :data="data" :options="options" />
  </div>
</template>

<script>
/* eslint-disable */

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import { getRandomColor } from '../common/tools'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

  

export default {
  name: 'ListCard',
  components: {
    Bar
  },
  props: {
    columns: Array,
    chartData: Array
  },
  // 
  // 一维数组 
  // category name, category value、
  // 
  // 二维数据
  // label, category name, category value
  // 2022年，赛力斯, 1000
  // 2023年，
  data() {
    return {
      data: {
        labels: this.chartData.length > 0 ? Object.keys(this.chartData[0]) : [], // ['2022年', '2023年'] x轴
        datasets: this.chartData.map((data, index) => ({ //  [{汕头市: 34, 珠海市: 36}, {汕头市: 24, 珠海市: 26}]
          label: this.columns[index], //['汕头市', '珠海市'] 
          data: Object.values(data),
          borderColor: `#36A808`, // 交替颜色
          backgroundColor: getRandomColor()
        }))
      },
      options: {
        responsive: true,
        plugins: {
          // https://chartjs-plugin-datalabels.netlify.app/guide/getting-started.html#registration
          datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    ctx.dataset.data.forEach((data) => {
                      sum += parseInt(data);
                    });
                    let percentage = Math.round((value / sum) * 100);
                    let text = percentage + '%';
                    if (percentage > 3)
                      return parseInt(value);
                    else
                      return ''
                },
                color: '#fff',
                labels: {
                title: {
                  font: {
                    size: 14,
                    weight: 'bold'
                  }
                }
              }
            },
      }
      }
    }
  },
  methods: {
    get_x_labels(data) {
      if (data.length == 0)
        return []

      const uniqueLabels = Array.from(new Set(data.map(item => item.label) || []));
      if (uniqueLabels.length > 1)
        return uniqueLabels
      else
        return Object.keys(data[0])
    }
  },
};
</script>