<template>
  <div :class="'h5-page ' + (miniapp ? '' : 'with-nav-bar')">
    <t-navbar title="我的" :fixed="true" v-if="!miniapp"></t-navbar>
      <div>
        <div v-if="!isLogin" class="profile t-row">您尚未登录。</div>
        <div v-else class="profile t-row">
          <div class="name-box" @click="gotoPage('profile/modify')">
            <div>Hi {{ account.userName }} 
              <icon-font name="edit" /> 
            </div>
            <div style="font-size:13px;color:#888;">
              <img src="https://m.gututu.net/images/vip.png" width="18" height="18" v-if="isVip">
              {{ account.profile }}</div>
          </div>
          <div class="avatar-box">
            <t-image class="avatar" :src="account.avatar" shape="round" />
          </div>
         
        </div>
      </div>

    <t-cell-group bordered>
      
      <t-cell v-if="isAdmin" :left-icon="vip_icon" title="芝麻开门" arrow hover @click="gotoPage('vip')"/>
      <!-- <t-cell v-if="isLogin" :left-icon="vip_icon" title="升级会员" arrow hover @click="gotoPage('vip')"/> -->
      <t-cell v-if="isLogin && !isVip" :left-icon="vip_icon" title="免费版本(3个画板，15个卡片)" arrow hover @click="gotoPage('vip')"/>
      <t-cell v-if="isLogin && isVip" :left-icon="vip_icon" title="vip版(马上续费)" arrow hover @click="gotoPage('vip')"/>
      
      <t-cell :left-icon="vip_icon" title="用户列表" arrow hover @click="gotoPage('users')"/>
      <!-- <t-cell v-if="isLogin" :left-icon="collect_icon" title="我的收藏" arrow hover @click="gotoPage('my_collections')"/> -->
      <!-- <t-cell v-if="isLogin" :left-icon="browse_icon" title="最近查看" arrow hover @click="gotoPage('recent_view')"/> -->
      <!-- <t-cell v-if="isLogin" :left-icon="browse_icon" title="赞过" arrow hover @click="gotoPage('my_like')"/> -->
      <t-cell :left-icon="about_icon" title="关于我们" arrow hover @click="gotoPage('about')"/>
      <t-cell :left-icon="setting_icon" title="设置" arrow hover @click="gotoPage('setting')"/>
      
      <t-cell :left-icon="feedback" title="问题反馈" arrow hover @click="gotoPage('feedback')"/>
      <t-cell v-if="isLogin" :left-icon="logout_icon" title="退出" arrow hover @click="logout()"/>
      <t-cell v-else :left-icon="lock" title="登录" arrow hover @click="gotoPage('login')"/>
      
    </t-cell-group>
    <div class="bottom-bar"> </div>
  </div>
  <GuTabBar selectedIndex="2"></GuTabBar>
 </template>
 
 <script>
   /* eslint-disable */
  import { h } from 'vue';
  import { UserVipIcon, CollectionIcon, BrowseIcon, Edit1Icon, LockOnIcon, ServiceIcon, InternetIcon, FormIcon, SettingIcon, LogoutIcon, HappyIcon } from 'tdesign-icons-vue-next';
  import GuTabBar from '@/components/GuTabBar.vue';
  import { Toast } from 'tdesign-mobile-vue';
  import { mapGetters, mapActions } from 'vuex';
  import { IconFont } from 'tdesign-icons-vue-next';
  import { isMiniApp } from '@/common/tools'


 export default {
   name: 'Mine',
   components: {
    GuTabBar,
    IconFont
   },
   computed: {
    ...mapGetters(['account', 'isLogin', 'isVip'])
   },
   data() {
     return {
       vip_icon: h(UserVipIcon),
       collect_icon: h(CollectionIcon),
       browse_icon: h(BrowseIcon),
       modify_icon: h(Edit1Icon),
       lock : h(LockOnIcon),
       service: h(ServiceIcon),
       internet: h(InternetIcon),
       feedback: h(FormIcon),
       setting_icon: h(SettingIcon),
       logout_icon: h(LogoutIcon),
       about_icon: h(HappyIcon),
       isAdmin: false,
       miniapp: false,
     };
   },
   created() {
      // const auth = useAuth();
      this.miniapp = isMiniApp()
   },

  //  https://txc.qq.com/products/668733
  // 意见反馈
   methods: {
    ...mapActions(['logoutSuccess']),
      gotoPage(page) {
        this.$router.push('/' + page);
      },
      logout() {
        // 删除cookie的token
        document.cookie = 'jwt=; path=/; max-age=0';
        var token = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1];
        this.logoutSuccess()
        Toast('退出成功')
      }
   }
 };
 </script>


<style scoped>


.profile {
  padding: 15px 20px;
  height: 160px;
  width: 100%;
  display: flex;
}
.name-box {
  float: left;
  margin-top:50px;
  /* width: 150px; */
  flex: 1;
  font-size: 20px;
  font-weight: bold;
}
.avatar-box {
  width: 100px;
  height: 100%;
  float: right;
  text-align: right;
  margin-top: 20px;
}
.avatar {
    width: 80px;
    height: 80px;
    border-radius: 45px;
}
</style>