<template>
  <div class="h5-page">
    <t-navbar title="隐私协议" :fixed="true" left-arrow @left-click="goBack">
  </t-navbar>

    <div id="app" class="container">
      <p>
      最近更新: {{update_date}}<br><br>

{{ company_name}}（“我们”）尊重其用户（“您”）的隐私，并制定本隐私政策以表明其对保护您隐私的承诺。 本隐私政策描述了我们收集的信息、如何使用这些信息，以及您对此类使用和披露的选择。 我们鼓励您在使用我们的应用程序（我们的“软件”或“应用程序”）或服务（我们的“服务”）之前仔细阅读本隐私政策。 通过使用我们的服务，即表示您接受本隐私政策中描述的做法。 如果您不同意此处规定的条款和条件，请不要使用该软件和服务。本政策仅适用于我们在我们的应用程序以及通过我们的应用程序发送或与我们的应用程序相关的电子邮件、文本和其他电子通信中收集的信息。如果您有任何问题或疑虑，请通过下面显示的电子邮件与我们联系。
</p>



<p><b>1.我们向您收集哪些类型的信息？</b><br><br>

我们将处理两大类信息：<br><br>

1.1 信息可能被自动收集当您使用或访问本软件和服务时，可能会自动收集有关您的一些信息，如下所示：<br><br>

·技术信息。 我们可能会收集某些与您的设备配置和标识符以及您对软件和服务的使用有关的技术信息，包括操作系统的类型、名称和版本、Open UDID，目的是改善您使用我们的服务的体验。<br>
<br>
·使用信息。 我们可能会收集有关您使用设备以及与设备（包括软件和服务）交互的某些使用相关信息，例如您使用软件和服务的时间和方式、使用频率，目的是改进我们的服务。<br>
<br>
·付款数据。 我们可能会收集有关您的订阅订单的信息，例如购买商品、订阅时间、订单状态、付款时间。 我们不收集付款详细信息，也不对付款详细信息的收集或安全负责。 付款信息由第三方公司（例如 Apple）处理和存储。 可以通过他们的网站联系这些公司：https://www.apple.com。 在输入您的个人付款详细信息之前，我们建议您阅读并熟悉这些第三方公司的隐私政策。<br>
<br>
1.2 您向我们提供的信息<br>
<br>
1.2.1 用户信息。您的昵称、电子邮箱以及您在我们这里创建的帐户信息。
<br>
1.2.2 通讯信息。 如果您与我们联系，我们会收集您的姓名、联系信息以及您发送的任何消息的内容。
<br>
1.2.3 访问存储的权限。 为确保您可以正常将照片、视频或缓存信息保存到您的设备，或将您设备中的图片上传到本软件，您可以自愿授予我们访问您设备存储空间的权限。
<br>
1.2.4 信息安全。您的所有数据（包括您的笔记与图片等）都仅存储在您的设备中，确保您的数据隐私和数据安全。



</p>
<p><b>2.我们不共享的信息</b><br><br>

2.1 信息安全。我们使用人工智能来处理您的笔记，但我们不会将你的任何信息用于训练目的，并且您的笔记在您的账号中保持绝对私密。

2.2 笔记信息。您的所有笔记，包括文本、语音、链接、文件等所有您输入的笔记信息，都仅您个人能查看和聆听。



</p>
<p><b>3.我们共享的信息</b><br><br>

除以下情况外，我们不会分享从您那里收集的任何个人信息：<br><br>

3.1 如果法律要求我们披露您的信息，我们可能会根据需要与执法机构或其他主管机构以及任何第三方共享您的信息（例如，为了检测、防止或以其他方式解决欺诈、安全或技术问题） ；回应索赔或满足任何法律程序、传票或政府要求；或保护我们的用户、其合作伙伴或公众的权利、财产或人身安全）；

<br><br>3.2 如果我们发生业务转型，例如被另一家公司合并或收购、合并、控制权变更、重组或出售其全部或部分资产，您的信息将属于被转让的资产；

<br><br>3.3 为了对您的输入提供反馈或回应，您理解并同意我们需要与我们的人工智能技术合作伙伴共享用户内容。

<br><br>3.4 我们还可能与我们的子公司、关联公司和母公司共享个人信息，但他们对此类信息的使用必须遵守适用的数据保护法和本隐私政策。



</p>
<p><b>4.我们如何使用您的信息？</b><br><br>

4.1 我们使用您的信息的主要原因是为了提供和改进服务。 请继续阅读，了解我们使用您的信息的各种原因的更详细说明：<br><br>

- 操作和更新软件和服务，并向您提供现有的和新的功能和特性；<br>

- 改进和定制软件和服务；<br>

- 维护、测试、监控和改进软件的质量和操作；<br>

- 为您提供支持并处理您的投诉和/或反馈；<br>

- 在涉及您的与软件和服务相关的争议时采取任何行动；<br>

- 遵守并满足任何适用的法律法规、法律程序或可执行的政府要求。<br>

<br>4.2 如果出现任何处理您的信息的新目的，我们将通过对本隐私政策进行相应的更改，让您知道我们开始处理该其他目的的信息。



</p>
<p><b>5.向境外传输数据</b><br><br>

5.1 我们在国际范围内工作并向世界各地的用户提供软件。 我们和为本软件提供自动数据处理技术的第三方组织可能会将自动处理的信息跨境传输，从您所在的国家或司法管辖区传输到世界各地的其他国家或司法管辖区。
<br><br>
5.2 如果您位于欧洲经济区，根据法律要求，您特此明确同意我们在您使用服务后将您的数据传输到欧洲经济区以外的地方。 您可以随时撤回您的同意。



</p>
<p><b>6.我们保留您的信息多久？</b><br><br>

我们通常会在履行您与我们之间的合同和遵守我们的法律义务所需的期限内保留您的个人信息。如果您不再同意或明确撤销对我们收集、使用、披露您的个人信息的同意 ，我们（以及与我们合作的任何第三方）将立即停止所有此类使用，您可以要求我们删除您的个人信息并关闭您的帐户。然而，如果信息是遵守法律义务（税务、会计、审计）或为了维护安全和数据备份设置、防止欺诈或其他恶意行为所必需的，某些信息可能仍会存储一段时间。



</p>
<p><b>7.我们如何保护您的信息</b><br><br>

我们采取包括管理、技术和物理措施在内的安全措施来保护您的个人信息免遭未经授权的访问和披露。 然而，任何系统都不可能是完全安全的。 因此，尽管我们采取措施保护您的信息，但我们不承诺（您也不应期望）您的个人信息将始终保持安全。



</p>
<p><b>8.访问、修改和删除您的信息的权利</b><br><br>

根据法律规定，您拥有访问、编辑和删除因您访问和/或使用服务而收集的您的个人信息的一般权利。 为了行使这项权利，请通过下面显示的电子邮件与我们联系。



</p>
<p><b>9.有关儿童的隐私政策</b><br><br>

我们提供的服务不适用于 17 岁以下或您所在国家/地区适用法律和法规规定的其他法定最低年龄（“最低年龄”）的儿童。 低于最低年龄的任何人都不应使用该服务。 考虑到这一点，我们不愿意收集最低年龄以下儿童的个人数据。 尽管如此，如果我们注意到此类数据的收集，我们将采取一切可能的有用措施删除相应的数据。 如果您认为我们可能拥有来自低于最低年龄的儿童的任何信息，请通过下面显示的电子邮件与我们联系。



</p>
<p><b>10.本隐私政策的变更</b><br><br>

本隐私政策可能会不时更改。 每当我们更改本隐私政策时，我们都会将这些更改发布到本隐私政策以及我们认为适当的其他位置。 请您不时查阅并密切关注本隐私政策。

</p>
<p><b>11.如何联系我们</b><br><br>
如果您对本服务或本隐私政策有任何疑问，或者您想获取当前文档的先前版本，请随时通过 {{email}} 与我们联系。
</p>

   </div>
  </div>
 </template>
 
 <script>
   /* eslint-disable */
 import axios from 'axios';

 import 'bootstrap/dist/css/bootstrap.min.css';


 export default {
   name: 'Agreement',
   components: {
     
   },  
   data() {
    return {
      update_date: '8月10日, 2024年',
      company_name: 'Gututu',
      email: 'hi@gututu.com',
    }
   },
   created() {
     console.log('created')

   },
   methods: {
      goBack() {
        this.$router.back();
      },
   }
 };
 </script>
  <style scoped>
  .container { padding: 0px 25px;}
  p {margin-top: 50px;}
</style>