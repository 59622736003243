<template>
  <div :class="'h5-page ' + (miniapp ? '' : 'with-nav-bar')">
    <t-navbar :title="title" :fixed="true" v-if="!miniapp"></t-navbar>

    <t-navbar :title="title" :fixed="true" left-arrow @left-click="goBack"  v-if="!is_home"></t-navbar>

    <t-overlay :visible="is_loading" />

    <G-Loading v-if="is_loading" />
    
      <t-tabs default-value="1"  @change="onChange" v-if="is_home">
        <!-- <t-tab-panel value="0" label="我的" v-if="isLogin"/> -->
        <t-tab-panel value="1" label="热门" />
        <t-tab-panel value="2" label="最新" />
      </t-tabs>

      <div id="app" class="container page-bg">
        <div class="failure" v-if="connect_failure">
          <t-result theme="warning" title="网络异常" description="请检查手机网络信号是否正常" class="space" />
        </div>
        
        <div class="search-bar" v-if="!connect_failure">
          <t-search v-model="search_keyword" :clearable="true" 
          shape="round" placeholder="请输入画板关键字" 
          @change="onChange" style="width:100%;"  
          enterkeyhint="search"
          @submit="search"
              ></t-search>
          <!-- <button class="btn btn-primary" style="width:60px;float:right;display: flex;" @click="search">搜索</button> -->
        </div>
        <!-- <button class="btn btn-primary" @click="search">搜索</button> -->
        
        <div class="empty-tip" v-if="pages.length == 0 && finished">
          <t-result theme="default" title="没有画板" :description="这是一段描述" class="space" />
        </div>
      
        <div class="infinite-scroll-list page-list">
          <div class="page infinite-scroll-item" v-for="page in pages" :key="page.page_id" @click="showPage(page.page_id)">
              <div class="title">
                  <span>{{page.title}}</span>
              </div>
              <div class="row">
                <div class="name" v-if="page.user"> 
                  <img :src="page.user.avatar" width="22" height="22" style="margin-top:4px;"> 
                  <div class="page-name-text">{{ page.user.name }} 
                  <img src="https://m.gututu.net/images/vip.png" width="18" height="18" v-if="page.user.vip_level > 0">
                  </div>
                </div>
                <div class="icon-btn" @click.stop="like(page.page_id)">
                  <img src="/images/like.png" width="20" height="22" style="margin-top:4px;"> <span style="margin-top:4px;">{{ page.like_count }}</span> 
                </div>
              </div>

              <!-- <div class="row">
                <div class="icon-btn" @click.stop="like(page.page_id)"> 
                    <icon-font name="thumb-up" /> <span>{{ page.like_count }}</span> 
                </div>
                <div class="icon-btn" @click.stop="comment(page.page_id)"> <icon-font name="chat-bubble" /> <span>0</span>   </div>
                <div class="icon-btn" @click.stop="share(page.page_id)"> <icon-font name="share-1" /><span>{{ page.share_count }}</span>  </div>
              </div> -->
          </div>
        </div>

        <YiInfiniteLoading
          :loading="isLoading"
          :finished="finished"
          offset="100%"
          :hideLoading="true"
          @loadMore="loadMore"
        >
          <t-loading theme="dots" size="40px" v-if="isLoading"/>

          <!-- <span class="tips" v-if="isLoading">正在加载</span> -->
          <!-- <span class="tips" v-if="finished && pages.length > 0">没有更多</span> -->
        </YiInfiniteLoading>
          
        <div class="clear" >
          <!-- <button @click="clear" >clear</button>
          <button v-if="!isLoading" @click="loadMore" >load more</button> -->
        </div>
          
        
    </div>
    <div class="bottom-bar"> </div>
    
  </div>
  <GuTabBar selectedIndex="1" v-if="is_home"></GuTabBar>
 </template>
 
 <script>
   /* eslint-disable */
   
 import api from '../common/api'; 
 import { Toast } from 'tdesign-mobile-vue';
 import 'bootstrap/dist/css/bootstrap.min.css';
 import { mapGetters } from 'vuex';
 import { IconFont } from 'tdesign-icons-vue-next';
 import YiInfiniteLoading from 'yi-infinite-loading-vue3'
 import GuTabBar from '../components/GuTabBar.vue';
 import { isMiniApp } from '../common/tools'

 export default {
   name: 'PageList',
   components: {
     IconFont,
     GuTabBar,
     YiInfiniteLoading
   },
   computed: {
    ...mapGetters(['account', 'isLogin']),
    // isVip(user) {
    //   return user ? user.vip_level > 0 : false
    // }
   },
   data: () =>{
        return {
            title: '发现',
            type: 'hot', // 页面类型 首页列表，我的收藏，最近查看
            pages: [],
            is_loading: false,
            connect_failure: false,
            isLoading: false,
            finished: false,
            page_number: 1,
            is_search: false,
            search_keyword: '',
            is_home: true, // 默认首页,
            miniapp: false
        }
   },
   created() {
      this.update_type('hot')
      const search_input = document.querySelectorAll('.search-bar input');
      if (search_input.length > 0) {
        search_input[0].setAttribute('enterkeyhint', 'search');
      }
      this.miniapp = isMiniApp()

   },
   methods: {
    goBack() {
      this.$router.back();
    },
    getLoadUrl() {
      var url = ''
      switch (this.type) {
        case 'home':
          url = this.$store.getters['isLogin'] ? '/pages/mine' : '/pages/hot'
          break;
        case 'my_collections':
          url =  '/pages/mine/collect'
          break;
        case 'recent_view':
          url =  '/pages/mine/view'
          break;
        case 'my_like':
          url =  '/pages/mine/like'
          break;
        case 'mine':
          url =  '/pages/mine'
          break;
        case 'hot':
          url =  '/pages/hot'
          break;
        case 'new':
          url =  '/pages/new'
          break;
        default:
          url =  '/pages/hot'
      }
      return url + '?page_num='  + this.page_number
    },
    update_type(type = 'home') {
      this.pages = []
      this.page_number = 1
      this.finished = false
      if (this.$route.fullPath.indexOf('my_collections') != -1) {
        this.type = 'my_collections'
        this.title = '我的收藏'
        this.is_home = false
      } else if (this.$route.fullPath.indexOf('recent_view') != -1) {
        this.type = 'recent_view'
        this.title = '最近查看'
        this.is_home = false
      } else if (this.$route.fullPath.indexOf('my_like') != -1) {
        this.type = 'my_like'
        this.title = '我赞过的'
        this.is_home = false
      } else {
        this.type = type
      }
      this.loadData()
    },
    loadData() {
      this.is_loading = true
      var url = this.getLoadUrl()
      // console.log(this.$route.fullPath)
      // console.log(url)

      api.get(url)
         .then(response => {
         const data = response.data.data;
         for (let item of data)
            this.pages.push(item);

          if (data.length < process.env.VUE_APP_PAGE_SIZE)
            this.finished = true

         this.isLoading = false
         this.is_loading = false
       })
       .catch(error => {
         this.connect_failure = true
         console.error('Fetching data failed', error);
       });
    },
    // 重新加载
    // clear() {
    //   this.pages = []
    //   this.page_number = 1
    //   this.loadData()
    // },
    loadMore() {
      if (this.isLoading || this.finished) {
        return
      }
      console.log('loadMore')
      this.isLoading = true
      this.page_number +=1;
      this.loadData()
    },
    search() {
      if (this.search_keyword == '') {
        // 重置
        this.update_type()
        this.loadData()
        return
      }

      var _self = this
      _self.is_loading = true
      var params = {
        page_num: 1,
        keyword: this.search_keyword
      }
      api.get('/search', {params: params})
       .then(response => {
         const data = response.data.data;
         this.pages = []
         for (let item of data)
            this.pages.push(item);

         if (data.length == 0)
            this.finished = true
         this.isLoading = false
         this.is_loading = false
       })
    },
    onChange(event, label) 
    {
      // 处理事件
      if (label == '我的') { 
        this.update_type('mine')
      } else if (label == '热门') {
        this.update_type('hot')
      } else if (label == '最新') {
        this.update_type('new')
      }
    },
    showPage(page_id) {
      this.$router.push('/pages/' + page_id);
    },
    like(page_id) {
      api.post('/pages/' + page_id + '/like')
       .then(response => {
         const result = response.data.status;
         if (result == 'success') {
           Toast('点赞成功')
         } else {
           Toast('点赞失败')
         }
       })
    },
    comment(page_id) {
      Toast('评论')
    },
    share(page_id) {
      Toast('分享')
    }
   }
 };
 </script>
 <style scoped>
 .search-bar {
    display: flex;
    padding: 10px 5px;
    width: 100%;
    height: 60px;
 }

 .page .title {
    font-size: 16px;
    width:100%;
    clear:both;
 }

 .page .name {
   display: flex;
   width: 170px;
   margin-top: 15px;
   margin-right:10px;
   float:left;
   height: 30px;
 }

 .page-name-text {
  margin-left:10px;
  margin-top:5px;
  width: 140px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出元素部分的文本 */
  text-overflow: ellipsis;
 }

 .page .icon-btn {
   display: flex;
   width: 80px;
   margin-top: 15px;
   margin-right:10px;
   float:left;
   height: 30px;
 }
 .page .icon-btn span {
    padding-left: 8px;
 }

 .failure {
  height: 300px;
  padding-top: 80px;
 }

 .empty-tip {
    background: #FFF;
    padding: 100px 0px;
 }
 .tips {
  text-align: center;
 }
 </style>