<template>
    <div>
      <t-skeleton theme="paragraph" :animation="gradient" :loading="true" v-if="!dataLoaded"></t-skeleton>
      <t-action-sheet v-model="showSheet" :items="action_items" @selected="handleSelected" @cancel="handleCancel" />

      <Transition name="slide-fade">
        <div :id="`card-${card_id}`" class="card" v-if="dataLoaded && !isDel" @click="updateCardTitle">
          
          <div v-if="isAccount(user_id)">
            <div v-if="!edit_title" @click.stop="showTitleEdit">
              <span class="card-title">{{ card.title }} </span> <icon-font name="edit" />
            </div>
            <div v-else>
              <input :id="`card-${card_id}-input`" type="text" 
              
              v-model="card.title" style="width: 100%;" 
              @click.stop="" 
              enterkeyhint="done"
              @keydown.enter="updateCardTitle">
            </div>
          </div>
          <div v-else>
            <span class="card-title" v-if="show_title == 1">{{ card.title }} </span>
          </div>

          <component :is="cardType" :columns="card.columns" :chartData="card.data" v-if="cardIsReady"/>
          <div v-else>
            卡片异常，请重新修改
          </div>

          <div class="card-footer" v-if="!cardIsReady">
            <span @click="modify" v-if="isAccount(user_id)"><icon-font name="edit" /> 修改</span> 
            <span @click="delCard" v-if="isAccount(user_id)"><icon-font name="delete" /> 删除</span>
          </div>

          <div class="card-footer" v-if="hide_btn == 0 && !sharing && cardIsReady">
            <!-- <span @click="copy"><icon-font name="chart-add" /> 复制</span>   -->
            <span @click="report"> <icon-font name="thumb-down" /> 报错 </span> 
            <span @click="share"> <icon-font name="share" /> 分享 </span> 
            <!-- <span @click="modify" v-if="isAccount(user_id)"><icon-font name="edit" /> 修改</span> 
            <span @click="delCard" v-if="isAccount(user_id)"><icon-font name="delete" /> 删除</span> -->
            <span @click="more" v-if="isAccount(user_id)"><icon-font name="ellipsis" /> 更多</span>
          </div>
        </div>
    
      </Transition>
    </div>
  
</template>
    
    <script>
    /* eslint-disable */
    import api from '../common/api'; 
    import { IconFont } from 'tdesign-icons-vue-next';
    import { Toast } from 'tdesign-mobile-vue';
    import { mapGetters } from 'vuex';
    import html2canvas from 'html2canvas';
    import { ActionSheet, ActionSheetItem } from 'tdesign-mobile-vue';

    import ListCard from './ListCard.vue';
    import LineCard from './LineCard.vue';
    import PieCard from './PieCard.vue';
    import BarCard from './BarCard.vue';
  
    export default {
      name: 'CardById',
      components: {
          ListCard,
          BarCard,
          LineCard,
          PieCard,
          IconFont
      },
      props: {
        user_id: Number,
        card_id: Number,
        // title: String,
        // type: String,
        // columns: Array,
        // data: Array,
        hide_btn: {
          type: Number, 
          default: 0 
        },
        show_title: {
          type: Number, 
          default: 1
        }
      },
      computed: {
        ...mapGetters(['account', 'isAccount', 'isLogin']),
        cardType() {
          switch (this.card.type) {
            case 'list':
              return 'ListCard';
            case 'pie':
              return 'PieCard';
            case 'line':
              return 'LineCard';
            case 'bar':
              return 'BarCard';
            default:
              return 'div';
          }
        }
      },
      data() {
        return {
          card: {},
          dataLoaded: false,
          isDel: false,
          sharing: false,
          showSheet: false,
          cardIsReady: false,
          edit_title: false, // 修改卡片标题
          action_items: ['复制卡片', '修改卡片', '调整位置', '删除卡片'],
        }
      },
    //   setup(props) {
    //     console.log('setup card_id' + this.card)
    //     const count = ref(0);

    //     const methods = {
    //         increment() {
    //             count.value++;
    //         },
    //         decrement() {
    //             count.value--;
    //         }
    //     };

    //     return {
    //         count,
    //         userId: props.userId,
    //         cardId: props.cardId,
    //         methods
    //     };
    //   },        
      created() {   
          this.loadData()
      },
      methods: {
        loadData() {
            api.get('/data?card_id=' + this.card_id)
                .then(response => {
                    const result = response.data
                    console.log(result.data[0].columns)
                    if (result.status =='success')
                    {
                        this.card = result.data[0]
                        if (this.card.data.length > 0)
                          this.cardIsReady = true

                        this.dataLoaded = true
                    }
                })
            .catch(error => {
                console.log(error)
                this.first_loading = false
            });
            console.log(this.dataLoaded)
        },
        more() {
          this.showSheet = true;
        },
        handleSelected(selected, selectedIndex) {
          ActionSheet.close();
          switch (selectedIndex) {
            case 0:
              this.copy()
              break;
            case 1:
              this.modify()
              break;
            case 2:
              this.adjust()
              break;
            case 3:
              this.delCard()
              break;
          }
        },
        delCard() {
          this.isDel = true;
          this.$emit('del-card', this.card_id);
          api.post('/cards/delete', {
            card_id: this.card_id,
          }).then(response => {
            const result = response.data;
            if (result.status =='success') {
              Toast.success('删除卡片成功')
            } else {
              Toast.error('删除卡片失败')
              setTimeout(() => {
                this.isDel = false;
              }, 800);
            }
          })
          .catch(error => {
            Toast.error('删除卡片失败')
            this.isDel = false;
            console.error(error);
          });
        },
        modify() {
          this.$router.push('/cards/' + this.card_id + '/edit');
        },
        copy() {
          this.$emit('copy-card', this.card_id);
        },
        report() {
          this.$emit('report-error', this.card_id);
        },
        adjust() {
          // Toast('调整位置')
          this.$emit('adjust-card');
          // this.$emit('adjust-card', this.card_id);
        },
        showTitleEdit() {
          this.edit_title = true;
          this.$nextTick(() => {  
            document.querySelector(`#card-${this.card_id}-input`).focus();
          })

        },
        updateCardTitle() {
          api.post(`/cards/${this.card_id}/update`, {title: this.card.title})
          .then(response => {
              const result = response.data.status
              if (result =='success') {
                this.edit_title = false
              } else {
                Toast.error('接口出错，卡片标题修改失败')
              }
            })
        },

        share() {
          const element = document.querySelector('#card-' + this.card_id);
          this.sharing = true;
  
          this.$nextTick(() => {
            // html2canvas(element).then((canvas) => {
            //   const imgData = canvas.toDataURL('image/png');
            //   const link = document.createElement('a');
            //   link.download = 'card.png';
            //   link.href = imgData;
            //   link.click();
            //   this.sharing = false;
            // })
  
          })
  
         
        }
      }
    };
    </script>
    
    <style scoped>
    .card {
      border: 1px solid #ccc;
      padding: 15px;
      margin: 10px;
    }

    .card-title {
      font-size: 18px;
      font-weight: bold;
    }

    .card-footer {
      margin-top: 10px;
    }
    .card-footer span{
      margin-right: 15px;
    }
  
    .slide-fade-enter-active {
      transition: all 0.3s ease-out;
    }
  
    .slide-fade-leave-active {
      transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
    }
  
    .slide-fade-enter-from,
    .slide-fade-leave-to {
      transform: translateX(150px);
      opacity: 0;
    }
    </style>