<template>
    <div class="h5-page with-nav-bar">
      
      <!-- <t-navbar :title="title" :fixed="true" v-if="is_home"></t-navbar> -->

      <G-Loading v-if="is_loading || modeling" />
      
      
      <div class="home-logo">
        <img src="https://m.gututu.net/images/icon.png" alt="logo" width="80px" style="border-radius: 15px"/>
        <div class="text">
                      <div class="slogan">股图图，AI金融分析画板</div>
                      <div class="desc">输入文字，大模型生成📈图表<br>
                        A港美股💰数据，准确性🎓更可靠<br></div>
         </div>
      </div>
      <div class="home-form">
          <div class="align-items-start h-50">
              <div class="col">
                  
                  <div class="magic">
                    
                      <textarea v-model="prompt" enterkeyhint="send"></textarea> 
                      <button class="btn btn-primary send-button" @click="magic" :disabled="modeling || prompt== ''" >
                        <icon-font name="send" />
                      </button>
                  </div>

                  <div class="tag-list">
                    <t-tag variant="outline" shape="round" v-for="(word, index) in show_prompt" :key="'prompt-' + index" @click="inputPrompt(word)">{{ word }}</t-tag>
                  </div>
                  <div class="">
                      
                  </div>
              </div>
          </div>

      </div>

     <t-tabs v-if="isLogin" defaultValue="0" @change="onChange">
        <t-tab-panel value="0" label="我的" v-if="isLogin"/>
        <t-tab-panel value="1" label="收藏" />
        <t-tab-panel value="2" label="赞过" />
        <t-tab-panel value="3" label="看过" />
      </t-tabs>
  
      <div id="app" class="container page-bg">

        <div class="failure" v-if="connect_failure">
          <t-result theme="warning" title="网络异常" description="请检查手机网络信号是否正常" class="space" />
        </div>
        
        <!-- <div class="search-bar" v-if="!connect_failure">
          <t-search v-model="search_keyword" :clearable="true" shape="round" placeholder="请输入关键字" @change="onChange"></t-search>
          
        </div> -->
        <!-- <button class="btn btn-primary" @click="search">搜索</button> -->

        <!-- lock-on -->
        
          <div class="infinite-scroll-list page-list" v-if="isLogin">
            <div class="page infinite-scroll-item" v-for="page in pages" :key="page.page_id" @click="showPage(page.page_id)">
                <div class="name">
                    <span>{{page.title}}</span>
                </div>
                <div class="row">
                  <div class="icon-btn"> 
                      <icon-font name="thumb-up" /> <span>{{ page.like_count }}</span> 
                  </div>
                  <div class="icon-btn"> <icon-font name="chat-bubble" /> <span>0</span>   </div>
                  <div class="icon-btn"> <icon-font name="share-1" /><span>{{ page.share_count }}</span>  </div>
                </div>
            </div>
          </div>
      
          <EmptyTips :word="empty_tips" v-if="pages.length == 0 && finished && !isLoading"/>

          <YiInfiniteLoading
            :loading="isLoading"
            :finished="finished"
            offset="100%"
            :hideLoading="true"
            @loadMore="loadMore"  v-if="isLogin"
          >
          <t-loading theme="dots" size="40px" v-if="isLoading"/>

            <!-- <span class="tips" v-if="isLoading">正在加载</span> -->
            <!-- <span class="tips" v-if="finished && pages.length > 0">没有更多</span> -->
          </YiInfiniteLoading>
            
          <div class="clear" >
            <!-- <button @click="clear" >clear</button>
            <button v-if="!isLoading" @click="loadMore" >load more</button> -->
          </div>
            
          
      </div>
      <div class="bottom-bar"> </div>
      
    </div>
    <GuTabBar selectedIndex="0"></GuTabBar>
   </template>
   
   <script>
     /* eslint-disable */
     
   import api from '../common/api'; 
   import { Empty, Toast } from 'tdesign-mobile-vue';
   import 'bootstrap/dist/css/bootstrap.min.css';
   import { mapGetters, mapActions } from 'vuex';
   import { IconFont } from 'tdesign-icons-vue-next';
   import YiInfiniteLoading from 'yi-infinite-loading-vue3'
   import GuTabBar from '../components/GuTabBar.vue';
   import EmptyTips from '../components/EmptyTips.vue';
   import LineCard from '../components/LineCard.vue'
   // const newIconfontUrl = 'https://tdesign.gtimg.com/icon/default-demo/index.css';
   import { isWechatEnv, isMiniApp } from '@/common/tools'

   export default {
     name: 'Home',
     components: {
       IconFont,
       GuTabBar,
       YiInfiniteLoading,
       EmptyTips,
       LineCard
     },
     computed: {
      ...mapGetters(['account', 'isLogin'])
     },
     data: () =>{
          return {
              title: '股图图',
              type: 'home', // 页面类型 首页列表，我的收藏，最近查看
              pages: [],
              is_loading: false,
              connect_failure: false,
              isLoading: false,
              finished: false,
              page_number: 1,
              is_home: true, // 默认首页
              empty_tips: '',
              modeling: false,
              miniapp: false,
              prompt: '',
              show_prompt: [],
              tip_prompt: [
                '深圳、广州、杭州A股上市公司数量对比',
                '市值超过1000亿的A股年涨幅前10强',
                'A股市值100亿以上52周新低，按市值前10',
                '特斯拉、比亚迪股价、市值，年涨幅对比',
                '杭州A股公司前10名',
                '今日A股涨跌数量分布',
                '美股最近20天跌幅最大的10只股票',
                '',
                '',
                ''
              ]
          }
     },
     created() {
      this.miniapp = isMiniApp()  // 判断是否在小程序内
      this.show_prompt = this.getRandomPrompts() // 生成随机提示语

      const code = this.$route.query.code
      if (code) {
        this.loginByWechat(code)
      }
      if (this.isLogin) {
        this.update_type()
        this.checkLoginState()
      } 

      // 小程序分享出去的卡片，点击来自动跳转
      if (this.$route.query.page_id)
      {
        this.$router.push('/pages/' + this.$route.query.page_id + '?from=share');
      }
      
     },
     methods: {
      ...mapActions(['loginSuccess', 'logoutSuccess']),
      getRandomPrompts() {
        // 使用slice()方法复制数组，避免修改原始数组
        let shuffledArray = this.tip_prompt.slice();

        // Fisher-Yates洗牌算法
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        // 返回数组中的前三个元素
        return shuffledArray.slice(0, 3);
      },
      inputPrompt(word) {
        this.prompt = word
      }, 
      getWehatCode() {
        
      },
      // 首次打开自动登录
      checkLoginState() {
        api.get('/checkin').then(response => {
          const result = response.data
          if (result.status =='success') {
            // Toast.success('已经登录')
          } else {
            Toast.error('登录无效')
            document.cookie = 'jwt=; path=/; max-age=0';
            var token = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1];
            this.logoutSuccess()
          }
        })
      },
      // 小程序自动登录
      loginByWechat(code) {
        var params = { code: code }
        api.post('/wechat_login', params).then(response => {
          const result = response.data
          if (result.status == 'success') {
            Toast.success('小程序免密登录成功')
            document.cookie = `jwt=${result.token};path=/;max-age=${60*60*24*7};`;
            this.loginSuccess(result.account)
          }
        })
      },
      getLoadUrl() {
        var url = ''
        switch (this.type) {
          case 'home':
            url = this.$store.getters['isLogin'] ? '/pages/mine' : '/pages/hot'
            break;
          case 'my_collections':
            url =  '/pages/mine/collect'
            break;
          case 'recent_view':
            url =  '/pages/mine/view'
            break;
          case 'my_like':
            url =  '/pages/mine/like'
            break;
          case 'mine':
            url =  '/pages/mine'
            break;
          case 'hot':
            url =  '/pages/hot'
            break;
          case 'new':
            url =  '/pages/new'
            break;
          default:
            url =  '/pages/hot'
        }
        return url + '?page_num='  + this.page_number
      },
      update_type(type = 'mine') {
        this.pages = []
        this.page_number = 1
        this.finished = false
        if (type == 'collect') {
          this.type = 'my_collections'
          this.title = '我的收藏'
          this.is_home = false
          this.empty_tips = '你还没有收藏画板哦'
        } else if (type == 'like') {
          this.type = 'my_like'
          this.title = '我赞过的'
          this.is_home = false
          this.empty_tips = '你还没有赞过别人的画板呢'
        } else if (type == 'view') {
          this.type = 'recent_view'
          this.title = '最近查看'
          this.is_home = false
          this.empty_tips = '你最近还没有查看画板哦'

        } else {
          this.type = 'mine'
          this.empty_tips = '马上生成一个新画板吧'
        }
        this.loadData()
      },
      loadData() {
        this.is_loading = true
        var url = this.getLoadUrl()
        // console.log(this.$route.fullPath)
        // console.log(url)
  
        api.get(url)
           .then(response => {
            const result = response.data
            if (result.status == 'success') {
              const data = result.data;
  
              for (let item of data)
                this.pages.push(item);
  
              if (data.length < process.env.VUE_APP_PAGE_SIZE)
                this.finished = true
            }

            this.isLoading = false
            this.is_loading = false         
         })
         .catch(error => {
           this.connect_failure = true
           this.isLoading = false
           this.is_loading = false
           console.log('Fetching data failed', error);
         });
      },
      // 重新加载
      // clear() {
      //   this.pages = []
      //   this.page_number = 1
      //   this.loadData()
      // },
      loadMore() {
        if (this.isLoading || this.finished) {
          return
        }
        console.log('loadMore')
        this.isLoading = true
        this.page_number +=1;
        this.loadData()
      },
      search() {
        if (this.search_keyword == '') {
          // 重置
          this.update_type()
          this.loadData()
          return
        }
  
        var _self = this
        _self.is_loading = true
        var params = {
          page_num: 1,
          keyword: this.search_keyword
        }
        api.get('/search', {params: params})
         .then(response => {
           const data = response.data.data;
           this.pages = []
           for (let item of data)
              this.pages.push(item);
  
           if (data.length == 0)
              this.finished = true
           this.isLoading = false
           this.is_loading = false
         })
      },
      onChange(event, label) 
      {
        // 处理事件
        if (label == '我的') { 
          this.update_type('mine')
        } else if (label == '收藏') {
          this.update_type('collect')
        } else if (label == '赞过') {
          this.update_type('like')
        } else {
          this.update_type('view')
        }
      },
      showPage(page_id) {
        this.$router.push('/pages/' + page_id);
      },
      like(page_id) {
        
        api.post('/pages/' + page_id + '/like')
         .then(response => {
           const result = response.data.status;
           if (result == 'success') {
             Toast('点赞成功')
           } else {
             Toast('点赞失败')
           }
         })
  
      },
      comment(page_id) {
        Toast('评论')
      },
      checkAndFit() {
        if (("standalone" in window.navigator) && window.navigator.standalone) {
          Toast.success('当前为独立打开模式')
        } else {
          Toast.success('当前为非独立打开模式')
        }
      },
      // 生成图表
      // 创建Page，创建Card
      // 文字 => Page title，card title
      // 若没有权限，则提醒
      magic(){
        if (!this.isLogin) {
          this.$router.push('/login?from=' + this.$route.path);
          return
        }

        this.modeling = true
        var url = '/magic?from=home'
        api.post(url, {
            prompt: this.prompt,
            }).then(response => {
                const result = response.data
                if (result.status == 'success') {
                  Toast.success('生成成功')
                  this.showPage(result.page_id)
                } else {
                  Toast(result.message)
                }
            }).finally(() => {
              this.modeling = false
            })
      }
     }
   };
   </script>
   <style scoped>
   .home-logo {
      margin-top: 20px;
      width: 100%;
      height: 200px;
      text-align: center;
   }

   .text {
      width: 380px;
      height: 80px;
      text-align: center;
      padding: 20px;
      /* padding-left: 80px; */
      margin: 0 auto;
      /* display: flex; */
   }

   .text .slogan {
      font-weight: bold;
      color: #222;
      font-size: 17px;
      margin-bottom: 5px;
   }
   .text .desc {
      color: #777;
   }

   .tag-list {
      text-align: left;
      margin-top: 10px;
   }
   .magic {
      height: 70px;
      display: flex;
      position: relative;
   }

   .magic-word {
    border: #ECECEC 1px solid ;
    padding: 2px;
    border-radius: 5px;
   }

   .send-button {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 1;
    border-radius: 8px;
    /* color: var(--msh-chat-send-button-color);
    background-color: var(--msh-chat-send-button-bg); */
    height: 36px;
    width: 40px;
   }

   .home-form {
      width: 100%;
      height: 150px;
      padding: 10px;
      text-align: center;
   }

   .home-form  textarea{
      flex: 1; 
      float:left; 
      height: 55px;
      margin-right: 5px;
      padding: 5px;
      padding-right: 50px;
      border-radius: 10px;
   }

   .home-form textarea:focus {
    border-color: #0d6efd; /* 设置聚焦时的边框颜色 */
    outline: none; /* 可选：移除默认的聚焦轮廓线 */
  }
  
   .search-bar {
  
      padding: 10px 5px;
      width: 100%;
      height: 60px;
   }
  
   .page .name {
      font-size: 16px;
      width:100%;
      clear:both;
   }
   .page .icon-btn {
     width: 80px;
     margin-top: 15px;
     margin-right:10px;
     float:left;
     height: 30px;
     
   }
   .page .icon-btn span {
      padding-left: 8px;
   }
  
   .failure {
    height: 300px;
    padding-top: 80px;
   }
  
  .page-list {
    padding-top: 15px;
  }


   </style>