import { createApp } from 'vue'
import router from './router'
import TDesign from 'tdesign-mobile-vue';
import App from './App.vue'
import authPlugin from './common/authPlugin';
import store from './store';
import Loading from './components/Loading.vue';

import 'tdesign-mobile-vue/es/style/index.css';
import '../public/global.css';

const app = createApp(App);

// 全局控件 
app.component('G-Loading', Loading);

app.use(router)
  .use(TDesign)
  .use(authPlugin)
  .use(store)
  .mount('#app')
