<template>
    <!-- URL: {{ $route.fullPath }} -->
  <main>
    <RouterView />
    
      <!-- <RouterView v-slot="{ Component }">
        <transition name="slide">
          <component :is="Component" />
        </transition>
      </RouterView> -->
    
  </main>
</template>

<script>
  /* eslint-disable */
export default {
  name: 'App',
  components: {
  },
  data: () => {
    return {
      transitionName: ''
    }
  },
  created() {
    this.updateTitle();
  },
  mounted() {
    this.$watch(() => this.$route, (to, from) => {
      // if (to.meta.index > from.meta.index) {
      //   this.transitionName ='slide-left';
      // } else if (to.meta.index < from.meta.index) {
      //   this.transitionName ='slide-right';
      // } 
      // console.log(to, from);
      this.updateTitle();
    });
  },
  methods: {
      updateTitle() {
        const title = this.$route?.meta?.title || '默认标题';
        document.title = title;
      }
  }
};
</script>
<style scoped>


</style>