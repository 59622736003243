<template>
  <t-navbar title="创建画板" :fixed="false"></t-navbar>
  <div id="app" class="container">
    <form @submit.prevent="submitForm">
      <input type="text" v-model="user_id" hidden>
      <t-input label="画板名称" placeholder="不超过30个中文" v-model="title"></t-input>
      <button class="btn btn-primary">保存</button>

  </form>

  <t-overlay :visible="is_loading" />
  <t-loading theme="dots" size="40px" v-if="is_loading"/>

  <hr>
    <div>
      <CardComponent
        v-for="item in cards"
        :key="item.title"
        :card_id="item.card_id"
        :title="item.title"
        :type="item.type"
        :columns="item.columns"
        :data="item.data"
      />
    </div>

      <div class="row align-items-start h-50">
            <div class="col">
                <div class="row mb-3">
                    MagicWord:
                </div>
                <div class="row mb-3 h-25">
                    <textarea v-model="prompt" class="w-100"></textarea>
                </div>
                <div class="row mb-3">
                    <button class="btn btn-primary" @click="text2json">生成</button>
                </div>
            </div>
        </div>

  </div>
  <GuTabBar selectedIndex="1"></GuTabBar>
 
</template>

<script>
 /* eslint-disable */
import api from '../common/api'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import CardComponent from '../components/Card.vue';
import GuTabBar from '../components/GuTabBar.vue';
import { Toast } from 'tdesign-mobile-vue';

export default {
 name: 'App',
 components: {
    CardComponent,
    GuTabBar
 },
 data() {
   return {
    new_page_id: 0,
    result: '',
    chartData : {},
    card: null,
    cards: [],
    user_id: 1,
    title: '',
    is_loading: false
  }
 },
 created() {
   console.log('created')
   
 },
 methods: {
  async submitForm() {
    try {
      const response = await api.post('/pages/create', {
        title: this.title,
      });
      // this.result =  JSON.stringify(response.data)
      this.new_page_id = response.data.page_id
    } catch (error) {
      console.error('Error submitting form:', error);
      Toast.error('出错了')
    }
  },
  text2json() {
      var _self = this
      _self.is_loading = true
      var url = '/magic?page_id=' + this.new_page_id
      api.post(url, {
          prompt: this.prompt,
          }).then(response => {
              // _self.result = response.data.data
              _self.card = response.data.data
              _self.is_loading = false
              console.log("card:" + _self.card)
              _self.data2chart()
              console.log(response.data)
          })
    },
    data2chart() {
        const url = '/data?card_id=' + this.card['card_id'];
        api.get(url)
        .then(response => {
            const result = response.data.data;
            this.cards.push(result[0])
            // this.chartData = result[0]
        })
        .catch(error => {
            console.error('Fetching data failed', error);
            // reject(error); // 使用 reject 来传递失败原因
        });
    }
 }
};
</script>

<style scoped>
.t-loading {
  margin-right: 64px;
  position: fixed;  /* 固定定位，相对于浏览器窗口定位 */
  top: 50%;        /* 距离顶部50% */
  left: 50%;       /* 距离左边50% */
  transform: translate(-50%, -50%); /* 将元素向左和向上移动自身的50%，实现居中 */

  z-index: 9999;    
  display: block;    
}
</style>